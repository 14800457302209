import React, { useEffect, useState } from 'react';
import axios from 'axios';

const TournamentResultsProcessing = ({ filename, tournamentId }) => {
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const timer = setInterval(async () => {
      try {
        const response = await axios.get(`/bc/tournaments/${tournamentId}.json`);
        if (response.data?.data.attributes.results_file_processed) {
          setIsProcessing(false);
          window.location.reload();
        }
      } catch (error) {
        console.error('Error fetching tournament data:', error);
      }
    }, 10000);

    return () => clearInterval(timer);
  }, [tournamentId]);

  if (!isProcessing) return null;

  return (
    <div className="row">
      <div className="col-6 offset-3">
        <div className="card">
          <div className="card-body text-center">
            <h2>Your Results Are Processing...</h2>
            <p>Your results file "<i>{filename}</i>" was successfully uploaded. It is now processing.</p>
            <p>Wait here for a few minutes until it completes.</p>
            <div className="pb-4">
              <i className="fad fa-spin fa-spinner-third"></i>
            </div>
            <p>If this is taking a while, please reload the page and/or contact support.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TournamentResultsProcessing;