import React, { useEffect, useState, useMemo } from 'react'

import FencerSelect from '@components/fencer-select'
import DivisionSelect from '@components/division-select'
import ClubSelect from '@components/club-select'

const fencerRowToLabel = r => {
  if( !r ) return null;
  const f = !!r?.attributes ? r.attributes : r;
  return `${f?.last_name}, ${f?.first_name} (${f?.primary_club_name}) [${f?.usfa_id || 'n/a'}]`;
}

const TeamForm = ({team, setTeam, fencers, setFencers}) => (
  <>
    <div className="form-group row">
      <label className="col-form-label col-3">Team Name <small>(required)</small></label>
      <div className="col-9">
        <input type="text" className="form-control" value={team?.attributes?.name || ""} onChange={(e) => {
          let newTeam = {...team};
          newTeam ||= {};
          newTeam.attributes ||= {};
          newTeam.attributes.name = e.target.value;
          setTeam(newTeam)
        }} />
      </div>
    </div>
    <div className="form-group row">
      <label className="col-form-label col-3">Division <small>(required)</small></label>
      <div className="col-9">
        <DivisionSelect value={team?.attributes?.division_id} onChange={(id) => {
          const newTeam = {...team};
          newTeam ||= {};
          newTeam.attributes ||= {};
          newTeam.attributes.division_id = id
          setTeam(newTeam)
        }} />
      </div>
    </div>
    <div className="form-group row">
      <label className="col-form-label col-3">Club <small>(required)</small></label>
      <div className="col-9">
        <ClubSelect value={team?.attributes?.club_id} onChange={(id) => {
          const newTeam = {...team};
          newTeam ||= {};
          newTeam.attributes ||= {};
          newTeam.attributes.club_id = id
          setTeam(newTeam)
        }} />
      </div>
    </div>
    <hr />
    {[0, 1, 2, 3].map(i => (
      <div className="form-group row" key={`${i}-${fencers[i]?.id}`}>
        <label className="col-form-label col-3">Fencer #{i+1} {i < 3 && (<small>(required)</small>)}</label>
        <div className="col-9">
          <FencerSelect fencerId={fencers[i]?.id} isClearable={i === 3} fencerValue={fencerRowToLabel(fencers[i])} onChange={(e) => {
            let newFencers = [...fencers];
            newFencers[i] = e;
            setFencers(newFencers);
          }} />
        </div>
      </div>  
    ))}
  </>
);

export default TeamForm;