import React, { useContext, useState, useEffect, useRef, forwardRef } from 'react'
import classnames from 'classnames'
import ReactTooltip from "react-tooltip"
import axios from '@/utils/axios'
import { MapComponent, MapContext } from '@/components/map-component'

const MapRow = forwardRef(({section, tournament, onClick}, ref) =>
  <div ref={ref} style={{}} className={`map-row d-flex align-items-center justify-content-between p-2 border-bottom ${tournament.attributes.is_cancelled ? 'cancelled' : ''}`}>
    <div className="d-flex flex-column cursor-pointer" style={{flexGrow: 1}} onClick={() => document.location = `/${tournament.type}s/${tournament.id}`}>
      <small className="text-muted">{tournament.attributes.dates} at {tournament.attributes.venue_name} in {tournament.attributes.venue_location}</small>
      <a href={`/${tournament.type}s/${tournament.id}`} style={{color: '#212529'}}>{tournament.attributes.name}</a>
    </div>
    
      <div className="d-flex align-items-center ml-1">
        {section === "results" ? (
          <>
            <a href={`/${tournament.type}s/${tournament.id}/results`} data-tip="Tournament Results"><i className="fas fa-fw fa-users" /></a>
            <ReactTooltip type='dark' effect='solid' />
          </>
        ) : (
          <>
            <a href="#"
               className="d-none d-md-inline"
               onClick={(e) => {onClick(); e.preventDefault(); return false;}}
               data-tip="View On Map">
              <i className="fas fa-fw fa-map" />
            </a>
            {tournament.attributes.can_register ? (
              <a href={`/${tournament.type}s/${tournament.id}/preregister`} data-tip="Preregister"><i className="far fa-fw fa-check-square"/></a>
            ) : (
              <a href="#" onClick={e => {e.preventDefault(); return false;}} data-tip="Registration Closed"><i className="fas fa-fw fa-times cursor-not-allowed"/></a>
            )}
            <a href={`/${tournament.type}s/${tournament.id}/preregistrations`} data-tip="Who's Coming?"><i className="far fa-fw fa-list-alt" /></a>
            <a href={`/${tournament.type}s/${tournament.id}/conversation`} data-tip="Message Organizers"><i className="far fa-fw fa-envelope" /></a>
            <ReactTooltip type='dark' effect='solid' />
          </>
        )}
      </div>
  </div>
);

const MoreLink = ({section}) => {
  if (section === "clinics"){
    return <a href="/clinics" style={{color: "#738699"}}>View More Clinics <i className="fa fa-arrow-right" /></a>
  }else if(section === "results"){
    return <a href="/results" style={{color: "#738699"}}>View More Results <i className="fa fa-arrow-right" /></a>
  }else {
    return <a href="/tournaments" style={{color: "#738699"}}>View More Tournaments <i className="fa fa-arrow-right" /></a>
  }
}


const HomeMap = ({ isBot, isMobile }) => {
  const {
    mapHeight,
    setCenter,
    setCanScroll,
    setMaxScroll,
    setSelectedMarkerId,
  } = useContext(MapContext);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState("upcoming");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dataContainerRef = useRef();
  const refList = useRef({});

  const loadData = () => {
    setLoading(true);
    axios.get(`/home.json`).then(res => {
      const newData = {}
      Object.keys(res.data?.data || {}).forEach(key => {
        const mainList = [];
        const canceledList = [];
        res.data.data[key]?.data?.forEach(row => {
          if(row.attributes.is_cancelled){
            canceledList.push(row)
          } else {
            mainList.push(row);
          }
        })
        newData[key] = {}
        newData[key].data = mainList.concat(canceledList)
      })
      setData(newData)
    }).finally(() => setLoading(false))
  }

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if( dataContainerRef.current ){
      let newMaxScroll = dataContainerRef.current.getBoundingClientRect().top + document.documentElement.scrollTop + dataContainerRef.current.offsetHeight - mapHeight - (mapHeight/2);
      if (newMaxScroll < 0) newMaxScroll = 0;
      setMaxScroll(newMaxScroll);
      setCanScroll(dataContainerRef.current.offsetHeight > mapHeight)
    }
  }, [dataContainerRef.current, section, mapHeight]);

  if (loading) {
    return <div className="w-100 d-flex align-items-center justify-content-center">
      <i className="fa fa-spinner-third fa-spin" style={{fontSize: 36}} />
    </div>
  }

  const shouldLoadMap = !isMobile && !isBot && windowWidth >= 768;

  return (
    <div className="row mx-0">
      <div className={`col-12 ${shouldLoadMap && 'px-0 px-md-2 col-md-6'}`}>
        <div className="d-flex align-items-center justify-content-evenly mb-3 flex-wrap">
          <button
            className={classnames("btn btn-sm text-nowrap", {
              "btn-secondary": section === 'upcoming',
              "btn-light": section !== 'upcoming'
            })}
            style={{borderRadius: 16, padding: '0.25rem 1rem', margin: '0 0.25rem 0.25rem 0.25rem', fontWeight: 500}}
            onClick={() => setSection("upcoming")}
          >
            Upcoming Tournaments
          </button>
          <button
            className={classnames("btn btn-sm text-nowrap", {
              "btn-secondary": section === 'clinics',
              "btn-light": section !== 'clinics'
            })}
            style={{borderRadius: 16, padding: '0.25rem 1rem', margin: '0 0.25rem 0.25rem 0.25rem', fontWeight: 500}}
            onClick={() => setSection("clinics")}
          >
            Upcoming Clinics
          </button>
          <button
            className={classnames("btn btn-sm text-nowrap", {
              "btn-secondary": section === 'newly_added',
              "btn-light": section !== 'newly_added'
            })}
            style={{borderRadius: 16, padding: '0.25rem 1rem', margin: '0 0.25rem 0.25rem 0.25rem', fontWeight: 500}}
            onClick={() => setSection("newly_added")}
          >
            Newly Added
          </button>
          <button
            className={classnames("btn btn-sm text-nowrap", {
              "btn-secondary": section === 'results',
              "btn-light": section !== 'results'
            })}
            style={{borderRadius: 16, padding: '0.25rem 1rem', margin: '0 0.25rem 0.25rem 0.25rem', fontWeight: 500}}
            onClick={() => setSection("results")}
          >
            Recent Results
          </button>
        </div>

        <div className="d-flex flex-column"
             ref={dataContainerRef}
             style={{minHeight: (shouldLoadMap ? mapHeight : undefined)}}
        >
          {data && data[section] && data[section].data ? (
            <>
              {data[section]?.data?.map(row =>
                <MapRow
                  ref={ref => refList.current[row.id] = ref}
                  key={row.id}
                  section={section}
                  tournament={row}
                  onClick={() => {
                    setCenter({lat: row.attributes.lat, lng: row.attributes.lng})
                    setSelectedMarkerId(row.id)
                  }}
                />
              )}
              <div style={{}} className="map-row d-flex align-items-center justify-content-end p-2">
                <MoreLink section={section}/>
              </div>
            </>
          ) : (
            <span>No results found</span>
          )}
        </div>
      </div>
      {shouldLoadMap &&
        <MapComponent
          refList={refList}
          data={data && data[section] && data[section].data}
        />
      }
    </div>
  );

};

export default HomeMap;