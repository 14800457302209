import React, { useContext, useEffect, useState } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"
import { MapContext } from './index';

import redPin from '@/images/red-pin.png'

const MapComponent = ({ data, refList }) => {
  const {
    center,
    canScroll,
    maxScroll,
    mapHeight,
    scrollPosition,
    selectedMarkerId,
    setSelectedMarkerId
  } = useContext(MapContext);

  const [markerData, setMarkerData] = useState(null);
  const [map, setMap] = React.useState(null)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAz8lrIs6QJN8e3-UqPXqYjCoLqhBZbWnc"
  })
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const mapContainerStyle={
    height: mapHeight,
      width: '50%',
      position: (canScroll && scrollPosition > 0 && scrollPosition < maxScroll) ? 'fixed' : 'absolute',
      right: 0,
      top: (canScroll && scrollPosition > maxScroll) ? (maxScroll + 200) : undefined,
      zIndex: 1,
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8
  }

  const highlightOnList = (id) => {
    const el = refList.current[id];
    if (!el) return;

    setSelectedMarkerId(id);
    el.scrollIntoView({behavior: 'smooth', block: 'center'});
    el.classList.add("active");
    setTimeout(() => el.classList.remove("active"), 500);
  }

  const pinSize = (id) => (selectedMarkerId === id ? [50, 50] : [35, 35])

  useEffect(() => {
    if (!isLoaded || !map) return;

    const venueData = data?.reduce((acc, row) => {
      acc[row.attributes.venue_name] = {id: row.id, lat: row.attributes.lat, lng: row.attributes.lng};
      return acc;
    }, {});

    setMarkerData(venueData);

    if(data?.length) {
      const bounds = new google.maps.LatLngBounds();
      Object.values(venueData).forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
      map.fitBounds(bounds);
      map.panTo(bounds.getCenter());
    }
  }, [isLoaded, map, data])

  useEffect(() => {
    if (map && center) {
      map.panTo(center);
    }
  }, [center]);

  if (!isLoaded) return <></>;

  return (
    <GoogleMap
      onLoad={setMap}
      onUnmount={onUnmount}
      defaultZoom={10}
      mapContainerStyle={mapContainerStyle}
      defaultCenter={center}
      options={{
        gestureHandling: "cooperative",
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
      }}
    >
      {markerData && Object.entries(markerData).map(([key, { lat, lng, id }]) => (
        <Marker
          key={key}
          position={{ lat, lng }}
          onClick={() => highlightOnList(id)}
          icon={{
            url: redPin,
            scaledSize: new google.maps.Size(...pinSize(id))
          }}
        />
      ))}
    </GoogleMap>
  )
};

export default MapComponent;