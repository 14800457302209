import React, { useState, useEffect } from 'react'

import axios from '@/utils/axios'

import AsyncSelect from 'react-select/async';

const jsonToLabel = (club) => {
  let label = `${club.attributes.name}`
  if (club.attributes.division_name) label = `${label} - ${club.attributes.division_name}`
  if (club.attributes.usfa_id) label = `${label} - USAF#${club.attributes.usfa_id}`
  if (club.attributes.cff_id) label = `${label} - CFF#${club.attributes.cff_id}`
  return (
    {
      value: club.id, 
      label
    }
  )
}

const ClubSelect = ({name, value, onChange, isClearable}) => {
  const [currentValue, setCurrentValue] = useState(null);

  useEffect(() => {
    if( !!value && value !== "" ){
      axios.get(`/clubs/${value}.json`).then(res => {
        const r = res.data.data;
        if(!!r) setCurrentValue(jsonToLabel(r))
      })
    }
  }, [value]);

  return (
    <AsyncSelect 
      cacheOptions
      loadOptions={(inputValue, callback) => {
        if( !inputValue ){
          callback([])
        } else {
          axios.get(`/clubs.json?search=${inputValue}`).then((res) => {
            callback(res.data?.data?.map(r => (jsonToLabel(r))));
          })
        }
      }}
      defaultOptions
      isClearable={!!isClearable}
      value={currentValue}
      placeholder="Type to search, click to select"
      noOptionsMessage={() => "Type to search for a Club" }
      name={name}
      onChange={(v) => {
        setCurrentValue(v)
        if(!!onChange) onChange(v?.value);
      }}
    />
  );
}

export default ClubSelect;