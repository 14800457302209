import React, { useState, useEffect } from 'react'

import axios from '@/utils/axios'

import Select from 'react-select';

const MyFencers = ({ id }) => {
  const [currentValue, setCurrentValue] = useState();
  const [options, setOptions] = useState([])

  useEffect(() => {
    axios.get(`/my/dashboard/fencers.json`).then((res) => {
      const newOptions = res?.data?.map(r => ({value: r.id, label: `${r.last_name}, ${r.first_name}`, data: r}));
      setOptions(newOptions);
      setCurrentValue(newOptions.find(row => row.value == id))
    })
  }, []);

  const onChange = (row) => {
    if( document.location.toString().indexOf("registrations/new") >= 0 ){
      document.location = document.location.pathname.toString() + "?fencer_id=" + row.value;
    } else {
      document.location = `/my/dashboard/fencers/${row.value}`
    }
  }

  return (
    <Select 
      cacheOptions
      options={options}
      value={currentValue}
      placeholder="Select one of your Fencers to view"
      noOptionsMessage={() => "Type to search for a Fencer" }
      onChange={onChange}
    />
  );
}

export default MyFencers;