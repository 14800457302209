import React, { useState, useEffect } from 'react'

import axios from '@/utils/axios'

import Select from 'react-select';

const EventSelect = ({tournamentId, name, onChange, noTeam, onlyTeam, single}) => {
  const [currentValue, setCurrentValue] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    axios.get(`/bc/tournaments/${tournamentId}/events.json`).then((res) => {
      let data = res?.data || [];
      if (noTeam) {
        data = data.filter(row => !row.is_team);
      }
      if (onlyTeam) {
        data = data.filter(row => !!row.is_team);
      }
      setOptions(data.map(r => ({value: r.id, label: `${r.full_name}`})));
      
    })
  }, [])

  useEffect(() => {
    if (options?.length === 1) {
      setCurrentValue(options)
      if(!!onChange) onChange(options)
    }
  }, [options]);

  return (
    <Select 
      cacheOptions
      isMulti={!single}
      options={options}
      value={currentValue}
      placeholder="Select one or more events"
      noOptionsMessage={() => "No events match!" }
      name={name}
      onChange={(v) => {
        setCurrentValue(v)
        if(!!onChange) onChange(v)
      }}
    />
  );
}

export default EventSelect;