import React, { useState, useEffect } from 'react'

import axios from '@/utils/axios'
import FencerSelect from '@/components/fencer-select'

const FencerSelectReload = ({ name, fencerId }) => {
  const [selectedFencer, setSelectedFencer] = useState(null);

  useEffect(() => {
    if( !!fencerId ){
      axios.get(`/fencers/${fencerId}.json`).then((res) => {
        const r = res.data;
        let memb = []
        if(r.usfa_id) memb.push(`USFA#${r.usfa_id}`)
        if(r.cff_id) memb.push(`CFF#${r.cff_id}`)
        if( !!r ) setSelectedFencer({id: r.id, value: `${r.last_name}, ${r.first_name} (${r.primary_club_name || ''}) [${memb.length > 0 ? memb.join(" ") : 'n/a'}]`})
      });
    }
  }, [fencerId])

  const selectFencer = (v) => {
    var url = new URL(document.location.toString());
    var search_params = url.searchParams;
    search_params.set('fencer_id', v.id);
    search_params.delete("new_reg");
    url.search = search_params.toString();

    document.location = url.toString();
  }

  return (
    <FencerSelect 
      name={name} 
      fencerId={selectedFencer?.id} 
      fencerValue={selectedFencer?.value} 
      onChange={selectFencer}
    />
  );
}

export default FencerSelectReload;