import React, { useState, useEffect } from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import axios from '@/utils/axios'
import qs from 'qs'

import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

const StripeCheckoutForm = ({intentUrl, successUrl}) => {
  // 1️⃣ Setup state to track client secret, errors and checkout status
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  

  // 2️⃣ Store reference to Stripe
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: successUrl,
      },
    });

    if (payload.error) {
      if (payload.error.type === "card_error" || payload.error.type === "validation_error") {
        setError(payload.error.message);
      } else {
        showMessage("An unexpected error occured.");
      }
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-2">
        <PaymentElement />
      </div>
      {!!error && <span>{error}</span>}
      <button className="btn btn-primary btn-lg text-center w-100" disabled={!stripe || processing}>
        Pay Now!
      </button>
    </form>
  )
}

const StripeCheckoutContainer = (props) =>  {
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    axios.post(props.intentUrl).then(res => {
      setClientSecret(res.data.clientSecret);
    })
  }, []);

  if( !clientSecret ){ 
    return <i className="fad fa-spinner-third fa-spin"></i>
  }

  return (
    <Elements stripe={props.stripePromise} options={{ clientSecret }}>
      <StripeCheckoutForm {...props} />
    </Elements>
  );
}

const StripeCheckout = (props) => {
  const stripePromise = loadStripe(process.env.STRIPE_API_PK, {
    stripeAccount: props.connectedStripeAccountId,
  });

  return <StripeCheckoutContainer stripePromise={stripePromise} {...props} />
}

export default StripeCheckout;
