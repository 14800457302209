import React, {useEffect, useState} from 'react';
import { MapContext } from './index';
import axios from "axios";

const MapContainer = ({children, latitude, longitude, isMobile = false, isBot = false}) => {
  const [canScroll, setCanScroll] = useState(false);
  const [center, setCenter] = useState(null);
  const [maxScroll, setMaxScroll] = useState(0);
  const [mapHeight, setMapHeight] = useState(window.innerHeight < 450 ? window.innerHeight : 450);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedMarkerId, setSelectedMarkerId] = useState(null);

  const handleResize = () => {
    setMapHeight(window.innerHeight < 450 ? window.innerHeight : 450);
  }

  const handleScroll = () => {
    setScrollPosition(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if ("geolocation" in navigator && isMobile && !isBot) {
      const updateLocation = () => {
        navigator.geolocation.getCurrentPosition(position => {
          const { latitude: lat, longitude: lng } = position.coords;

          axios.post('/session/update_location', { lat, lng }, {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
          }).then(
            () => setCenter({ lat, lng })
          );
        });
      };

      updateLocation();
      const intervalId = setInterval(updateLocation, 20 * 60 * 1000); // Every 20 minutes

      return () => clearInterval(intervalId);
    } else {
      setCenter({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
    }
  }, [isMobile, isBot]);

  return (
    <MapContext.Provider value={{
      canScroll, setCanScroll,
      center, setCenter,
      maxScroll, setMaxScroll,
      mapHeight,
      scrollPosition,
      selectedMarkerId, setSelectedMarkerId
    }}>
      {children}
    </MapContext.Provider>
  );
};

export default MapContainer;