import React, { useEffect, useState } from 'react'

import axios from '@/utils/axios'
import qs from 'qs'

import TeamForm from '@components/team-form'


const TeamPreregistration = ({preregistrationId}) => {
  const [preregistration, setPreregistration] = useState(null);
  const [team, setTeam] = useState(null)
  const [error, setError] = useState(null)
  const [fencers, setFencers] = useState(Array(4))
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [editing, setEditing] = useState(true);

  useEffect(() => {
    if(!!preregistrationId) getPreregistration(preregistrationId)
  }, [preregistrationId])

  const getPreregistration = (id) => {
    setLoading(true)
    axios.get(`/preregistrations/${id}.json`).then(res => {
      setPreregistration(res.data.data)
      setTeam(res.data.data?.attributes?.entity?.data)
      setFencers(res.data.data?.attributes?.entity?.data?.attributes?.fencers?.data || [])
      setEditing(!res.data.data?.attributes?.entity?.data?.attributes?.complete)
      setLoading(false)
    })
  }

  const save = () => {
    setDisabled(true)
    let payload = {
      team: {
        name: team?.attributes?.name,
        club_id: team?.attributes?.club_id,
        division_id: team?.attributes?.division_id,
        fencer_ids: fencers.map(f => f?.id)
      }
    }

    axios.put(`/preregistrations/${preregistration.id}.json`, qs.stringify(payload, { arrayFormat: 'brackets' })).then(res => {
      setPreregistration(res.data.data)
      setTeam(res.data.data?.attributes?.entity?.data)
      setFencers(res.data.data?.attributes?.entity?.data?.attributes?.fencers?.data || [])
      setDisabled(false)
      setEditing(false)
      setError(null)
    }).catch(error => { 
      setError("There was an error saving this team. Please try again.  IF this event has restrictions, please ensure all fencers are eligible.")
      setDisabled(false)
    })
  }

  if( loading ){
    return (
      <div className="w-100 d-flex align-items-center justify-content-center">
        <i className="fa fa-spin fa-circle-notch fa-2x"></i>
      </div>
    )
  }

  if( !editing ){
    return (
      <div className="w-100">
        {!!team?.attributes?.complete ? (
          <><i className="fas fa-check mr-2"></i> Team "{team.attributes.name}" is fully Registered! </>
        ) : (
          <><i className="fas fa-check mr-2"></i> Team "{team.attributes.name}" is saved but not complete. </>
        )}
        <button className="btn btn-primary btn-xs ml-2" onClick={() => setEditing(true)}> <i className="fa fa-edit"></i> Edit This Team</button>
      </div>
    )
  }

  return (
    <div>
      {!team?.attributes?.complete && (
        <div className="alert alert-warning">
          <i className="fas fa-exclamation-triangle mr-2"></i>
          <b>This team registration is not yet complete!</b> You can save at any time, but for your registration to be valid, you must complete all required fields and save.
        </div>
      )}
      <TeamForm team={team} setTeam={setTeam} fencers={fencers} setFencers={setFencers} />

      {!!error && (
        <div className="alert alert-danger">
          <i className="fas fa-exclamation-triangle mr-2"></i>
          {error}
        </div>
      )}

      <button className="btn btn-primary" disabled={disabled} onClick={save}>Save This Team</button>
    </div>
  )
};

export default TeamPreregistration;