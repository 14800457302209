import React, { useState, useEffect } from 'react';
import {Elements} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from '@/utils/axios'
import qs from 'qs'

import { useStripe, useElements } from '@stripe/react-stripe-js';

const StripeCheckoutResultContainer = (props) =>  {
  const [stripe, setStripe] = useState(null)
  const [payment, setPayment] = useState(null)

  useEffect(() => {
    props.stripePromise.then(s => setStripe(s))
  }, [])

  useEffect(() => {
    if( stripe ){
      stripe.retrievePaymentIntent(props.paymentIntentClientSecret).then(res => {
        setPayment(res.paymentIntent)
      });
    }
    
  }, [stripe]);

  if( !payment ){ 
    return <i className="fad fa-spinner-third fa-spin"></i>;
  }

  if( payment.status === 'succeeded' ){
    return (
      <>
        <h2>Success!</h2>
        <p>Your payment was successfully processed!</p>
      </>
    );
  } else if( payment.status === 'processing' ){
    return (
      <>
        <h2>Processing...</h2>
        <p>Your payment is still processing.  Reload this page for an updated status.</p>
      </>
    );
  } else if ( payment.status === 'requires_payment_method' ){
    return (
      <>
        <h2>Failure!</h2>
        <p>Your payment was unsuccessful due to an issue with your payment method, please try again.</p>
      </>
    );
  } else {
    return (
      <>
        <h2>Error!</h2>
        <p>There was an unknown error, please try again.</p>
      </>
    );
  }
  
}


const StripeCheckoutResult = (props) => {
  const stripePromise = loadStripe(process.env.STRIPE_API_PK, {
    stripeAccount: props.connectedStripeAccountId,
  });

  return (
    <div className="card">
      <div className="card-body">
        <StripeCheckoutResultContainer stripePromise={stripePromise} {...props} />
      </div>
    </div>
  );
}

export default StripeCheckoutResult;