import React, { useState, useEffect } from 'react'

import axios from '@/utils/axios'

import Select from 'react-select';

const AUTHORITIES = [
  { label: "USA Fencing", value: "USFA"}, 
  { label: "Canadian Fencing Federation", value: "CFF"}, 
  { label: "Fencing In The Schools", value: "FITS"}, 
  { label: "Professional Fencing League", value: "PFL"}, 
  { label: "Amateur Athletic Union", value: "AAU"}, 
  { label: "British Fencing", value: "BFA"}, 
  { label: "None", value: "NONE"}, 
  { label: "Other", value: "OTHER"},
];

const USFA_EVENT_LEVELS = [
  { label: "Local", value: "local"}, 
  { label: "Regional", value: "regional"}, 
  { label: "National", value: "national"}, 
];

const USFA_EVENT_RATINGS = ["U", "E1", "D1", "C1", "C2", "C3", "B1", "B2", "B3", "A1", "A2", "A3", "A4"];

const USFA_REGIONS = [1, 2, 3, 4, 5, 6];

const AuthorityFilter = ({isResults}) => {
  const [authority, setAuthority] = useState(null);
  const [usfaRating, setUsfaRating] = useState(null);
  const [usfaRegion, setUsfaRegion] = useState(null);
  const [usfaEventLevel, setUsfaEventLevel] = useState(null);

  useEffect(() => {
    const url = new URL(window.location.href.replace(/#/g,"?"));
    setAuthority(url.searchParams.get("authority"));
    setUsfaRating(url.searchParams.get("usfa_event_rating"));
    setUsfaRegion(url.searchParams.get("usfa_region"));
    setUsfaEventLevel(url.searchParams.get("usfa_event_level"));
  }, [])

  return (
    <div className="row">
      <div className="col-12 col-lg-4 mb-2">
        <Select 
          options={AUTHORITIES}
          value={AUTHORITIES.find(row => row.value === authority)}
          placeholder="Any Authority"
          noOptionsMessage={() => "No authorities match!" }
          name={"authority"}
          isClearable={true}
          onChange={(v) => {
            setAuthority(v?.value)
          }}
        />
      </div>
      {authority === "USFA" ? (
        <div className="col-12 col-lg-8 mb-2">
          <div class="row">
            <div className="col-12 col-sm-4 mb-2">
              <Select 
                options={USFA_EVENT_RATINGS.map(r => ({value: r, label: r}))}
                value={usfaRating && {value: usfaRating, label: usfaRating}}
                placeholder={`${isResults ? "" : "Est."} Rating`}
                noOptionsMessage={() => "No ratings match!" }
                name={"usfa_event_rating"}
                isClearable={true}
                onChange={(v) => {
                  setUsfaRating(v?.value)
                }}
              />
            </div>
            <div className="col-12 col-sm-4 mb-2">
              <Select 
                options={USFA_EVENT_LEVELS}
                value={usfaEventLevel && {value: usfaEventLevel, label: USFA_EVENT_LEVELS.find(row => row.value === usfaEventLevel).label}}
                placeholder={`Level`}
                noOptionsMessage={() => "No levels match!" }
                name={"usfa_event_level"}
                isClearable={true}
                onChange={(v) => {
                  setUsfaEventLevel(v?.value)
                }}
              />
            </div>
            {usfaEventLevel === "regional" && (
              <div className="col-12 col-sm-4 mb-2">
                <Select 
                  options={USFA_REGIONS.map(r => ({value: r, label: `${r}`}))}
                  value={usfaRegion && {value: usfaRegion, label: `${usfaRegion}`}}
                  placeholder={`Region`}
                  noOptionsMessage={() => "No regions match!" }
                  name={"usfa_region"}
                  isClearable={true}
                  onChange={(v) => {
                    setUsfaRegion(v?.value)
                  }}
                />
              </div>
            )}
          </div>
        </div>
      ) : <div className="col-8" />}
    </div>
  );
}

export default AuthorityFilter;