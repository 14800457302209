import React, { useState, useEffect } from 'react'

import axios from '@/utils/axios'

import AsyncSelect from 'react-select/async';

const FencerSelect = ({ name, fencerId, fencerValue, onChange, isClearable}) => {
  const [currentValue, setCurrentValue] = useState(!!fencerId ? {label: fencerValue, value: fencerId} : null);

  useEffect(() => {
    if(onChange && !!currentValue?.data) onChange(currentValue.data)
    if(onChange && isClearable && !currentValue) onChange(null)
  }, [currentValue?.value])

  useEffect(() => {
    setCurrentValue( !!fencerId && !!fencerValue ? {label: fencerValue, value: fencerId} : null)
  }, [fencerId, fencerValue])

  return (
    <AsyncSelect 
      cacheOptions
      isClearable={!!isClearable}
      loadOptions={(inputValue, callback) => {
        if( inputValue.length === 0 ) {
          callback([])
        } else {
          axios.get(`/fencers.json?search=${inputValue}`).then((res) => {
            callback(res?.data?.map(r => {
              let memb = []
              if(r.usfa_id) memb.push(`USFA#${r.usfa_id}`)
              if(r.cff_id) memb.push(`CFF#${r.cff_id}`)
              return {value: r.id, label: `${r.last_name}, ${r.first_name} (${r.primary_club_name}) [${memb.length > 0 ? memb.join(" ") : 'n/a'}]`, data: r}
            }));
          })
        }
        
      }}
      defaultOptions
      value={currentValue}
      placeholder="Type to search, click to select"
      noOptionsMessage={() => "Type to search for a Fencer" }
      name={name}
      onChange={(v) => setCurrentValue(v)}
    />
  );
}

export default FencerSelect;