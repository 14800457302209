import React, { useEffect, useState } from 'react'

import axios from '@/utils/axios'
import _ from 'lodash';

import Select from 'react-select';

const formatGroupLabel = (data) => (
  <div className="w-100 d-flex align-items-center justify-content-between">
    <span>{data.label}</span>
    <span>{data.options.length}</span>
  </div>
);

const DivisionFilter = ({name, value}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    axios.get(`/divisions.json`).then(res => {
      const newOptions = {};
      const newOptionsArray = [];
      
      res.data.data.forEach(r => {
        if( !newOptions[r.attributes.auth || "Uncategorized"] ){
          newOptions[r.attributes.auth || "Uncategorized"] = [];
        }
        const val = {
          value: r.id, 
          label: r.attributes.name + (!!r.attributes.abbrev ? ` (${r.attributes.abbrev})` : '')
        };
        newOptions[r.attributes.auth || "Uncategorized"].push(val)
        newOptionsArray.push(val)
      });
    
      setDefaultValue(value.split(",").map(v => newOptionsArray.find(x => x.value === v)))
      
      setOptions( 
        [{label: "USA Fencing", options: _.sortBy(newOptions["USFA"], 'label')}, {label: "Canada Fencing", options: _.sortBy(newOptions["CFF"], 'label')}, ].concat(
          Object.keys(newOptions).filter(x => !["USFA", "CFF"].includes(x)).map(o => ({label: o, options: _.sortBy(newOptions[o], 'label')}))
        ) 
      );

      setIsLoaded(true);
    })
  }, []);


  if (!isLoaded && options.length <= 0){
    return <i className="fa fa-spinner-third fa-spin" />;
  }

  return (
    <Select 
      isMulti
      isSearchable
      placeholder="Search by Division(s)..."
      options={options}
      defaultValue={defaultValue}
      name={name}
      formatGroupLabel={formatGroupLabel}
    />
  );
}

export default DivisionFilter;