import React, { useEffect, useState } from 'react'

import axios from '@/utils/axios'

import AsyncSelect from 'react-select/async';

const DivisionSelect = ({name, onChange, value, isClearable}) => {
  const [currentValue, setCurrentValue] = useState(null);

  useEffect(() => {
    if( !!value ){
      axios.get(`/divisions/${value}.json`).then(res => {
        const r = res.data.data;
        const label = r.attributes.name + (!!r.attributes.abbrev ? ` (${r.attributes.abbrev})` : '')
        if(!!r) setCurrentValue({value: r.id, label} )
      })
    }
  }, [value]);

  return (
    <AsyncSelect 
      cacheOptions
      loadOptions={(inputValue, callback) => {
        axios.get(`/divisions.json?search=${inputValue}`).then((res) => {
          callback(res.data.data?.map(r => ({value: r.id, label: r.attributes.name + (!!r.attributes.abbrev ? ` (${r.attributes.abbrev})` : '')})));
        })
      }}
      isClearable={isClearable}
      defaultOptions
      value={currentValue}
      placeholder="Type to search, click to select"
      noOptionsMessage={() => "Type to search for a Division" }
      name={name}
      onChange={(v) => {
        setCurrentValue(v)
        if(!!onChange) onChange(v?.value);
      }}
    />
  );
}

export default DivisionSelect;