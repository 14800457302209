import React, { useState } from 'react'

import axios from '@/utils/axios'

const FavoriteTournament = ({initialValue, tournamentId}) => {
  const [currentValue, setCurrentValue] = useState( !!initialValue );
  return (
    <i 
      className={`${currentValue ? 'fas' : 'far'} fa-star`} 
      style={{cursor: "pointer", color: currentValue ? "#FFD400" : "#000000"}} 
      onClick={() => {
        const url = currentValue ? 'unfavorite' : 'favorite';
        axios.put(`/tournaments/${tournamentId}/${url}.json`).then((res) => {
          setCurrentValue( !currentValue );
        })
      }}
    ></i>
  );
}

export default FavoriteTournament;